<template>
  <div class="audio-container">
    <img src="../../../assets/share_top.png" style="width: 100%;height: 56px;object-fit: fill">
    <div style="flex:1;width: 100%;height: 100%;overflow-y: auto">
      <div style="width: 100%;padding: 20px;box-sizing: border-box;">
        <div style="display: flex;width: 100%">
          <div style="flex: 1;width: 100%;padding-right: 5px;box-sizing: border-box">
            <p class="song-name">{{ currentSongInfo.song_name }}</p>
            <p class="song_name_sub">{{ currentSongInfo.song_name_sub }}</p>
          </div>
          <p class="btnGoQuiz" @click="gotoQuizAnswer">进入答题</p>
        </div>
        <!--currentSongInfo.song_lyrics_web && currentSongInfo.song_lyrics_web!=''-->
        <div v-if="false" class="lrcContainer">
          <p v-html="currentSongInfo.song_lyrics_web" style="text-indent: 2rem"></p>
        </div>
        <div v-else class="lrcContainer">
          <div v-for="(lyry,index) in currentSongInfo.song_lyrics_paragraph" :key="index" style="margin-bottom: 8px">
            <p v-html="lyry" style="text-indent: 2rem"></p>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 100px;text-align: center">
      <div class="process">
        <el-slider
            @change="changeSlide"
            v-model="currentRate"
            :show-tooltip="false"
        ></el-slider>
      </div>
      <div class="time">
        <p style="flex: 1;text-align: left">{{ formatTimeLength(currentPlayLength) }}</p>
        <p>{{ formatTimeLength(currentTotalLength) }}</p>
      </div>
      <div style="display: flex;justify-content: center">
        <img src="../../../assets/icon_audio_pre.png" @click="playPreVideo" class="btnPreNext">
        <div style="margin: 0px 15px">
          <img v-if="audioPlayStatus==0 ||audioPlayStatus ==2 " src="../../../assets/icon_audio_play.png"
               @click="startPlayAudio(false)"
               class="btn-audio-play">
          <img v-else src="../../../assets/icon_audio_pause.png" @click="pauseAudio" class="btn-audio-play">
        </div>
        <img src="../../../assets/icon_audio_next.png" @click="playNextVideo" class="btnPreNext">
      </div>
    </div>
    <audio ref="audioRefApp" style="display: none"/>
  </div>
</template>

<script>
import {getCourseInfo, getSongInfoV1} from "../../../api/keneng";
import {Loading} from "element-ui";
export default {
  name: "AuditionAudioDetail",
  data() {
    return {
      course_id: this.$route.query.course_id,
      currentPlayLength: 0,
      currentTotalLength: 0,
      currentRate: 0,
      audioPlayStatus: 0,
      activeName: 'first',
      currentPlaySongIndex: 0,
      songs: [],
      currentSongInfo: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    gotoQuizAnswer() {
      this.$router.push({
        path: '/quiz',
        query: {
          course_id: this.course_id
        }
      })
    },
    formatTimeLength(time) {
      if (time === 0) {
        return '00:00'
      } else {
        const minute = Math.floor(time / 60)
        const second = Math.floor(time % 60)
        return `${minute < 10 ? '0' + minute : minute}:${
            second < 10 ? '0' + second : second
        }`
      }
    },

    playPreVideo() {
      let index = this.currentPlaySongIndex
      index--
      if (index < 0) {
        this.$message.warning('已经是第一个音频了')
        return
      }
      this.currentPlaySongIndex = index
      setTimeout(() => {
        this.refreshSong()
      }, 300)

    },
    playNextVideo() {
      let index = this.currentPlaySongIndex
      index++
      if (index > (this.songs.length - 1)) {
        this.$message.warning('已经是最后一个音频了，现在进入答题模式～')
        this.gotoQuizAnswer()
        return
      }
      this.currentPlaySongIndex = index
      setTimeout(() => {
        this.refreshSong()
      }, 300)
    },

    changeSlide() {
      const audio = this.$refs['audioRefApp']
      let currentTime = (this.currentRate / 100) * this.currentTotalLength
      audio.currentTime = currentTime
    },
    fetchData() {
      getCourseInfo(this.course_id).then((res) => {
        this.songs = res.data.songs
        this.refreshSong()
      })
    },
    refreshSong() {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      this.resetPlayData()
      let song = this.songs[this.currentPlaySongIndex]
      let song_id = song['_id']['\$id']
      getSongInfoV1(song_id).then((res) => {
        this.currentSongInfo = res.data.data
        this.startPlayAudio(true)
      }).finally(()=>{
        loadingInstance.close()
      })
    },
    pauseAudio() {
      this.audioPlayStatus = 2
      const audio = this.$refs['audioRefApp']
      audio.pause()
    },
    startPlayAudio(force) {
      const audio = this.$refs['audioRefApp']
      if (this.audioPlayStatus == 2 && force == false) {
        audio.play()
        return
      }
      const url = this.currentSongInfo.song_audio_address
      console.log('url :' + url)
      audio.src = url
      let that = this
      audio.onplay = () => {
        this.audioPlayStatus = 1
      }
      // 暂停
      audio.onpause = () => {
        that.audioPlayStatus = 2
      }
      // 停止
      audio.onended = () => {
        that.audioPlayStatus = 0
      }
      // 播放进度改变
      audio.ontimeupdate = () => {
        this.currentPlayLength = audio.currentTime
        if (audio.duration) {
          this.currentTotalLength = audio.duration
        }
        this.currentRate = (audio.currentTime / audio.duration) * 100
      }
      audio.play()
    },
    getOptionIndex(index) {
      switch (index) {
        case 0:
          return 'A'
        case 1:
          return 'B'
        case 2:
          return 'C'
        case 3:
          return 'D'
        case 4:
          return 'E'
        case 5:
          return 'F'
      }
    },

    resetPlayData() {
      const audio = this.$refs['audioRefApp']
      audio.pause()
      this.currentPlayLength = 0
      this.currentTotalLength = 0
      this.currentRate = 0
      this.audioPlayStatus = 0
    }
  },
  beforeDestroy() {
    const audio = this.$refs['audioRefApp']
    audio.pause()
  }
}
</script>

<style scoped lang="less">

.btnGoQuiz {
  cursor: pointer;
  width: 72px;
  line-height: 32px;
  height: 32px;
  text-align: center;
  border-color: #5D5BB5;
  border-style: solid;
  border-radius: 5px;
  box-sizing: border-box;
  color: #5D5BB5;
  font-size: 14px;
}

.time {
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  box-sizing: border-box;
  width: 100%;
  color: #858585;
  letter-spacing: 1px;
  font-size: 14px;
}

.btnPreNext {
  width: 36px;
  height: 36px;
  margin-top: 20px;
  object-fit: cover;
  cursor: pointer;
}

.process {
  padding: 0px 20px;
  box-sizing: border-box;
  width: 100%;
}

.btn-audio-play {
  cursor: pointer;
  width: 98px;
  height: 98px;
  object-fit: cover;
}

.song-name {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
}

.song_name_sub {
  margin-top: 15px;
  color: #6E707D;
  font-size: 14px;
}

.lrcContainer {
  margin-top: 25px;
  color: #454651;
  font-size: 16px;
  line-height: 28px;
}

/deep/ .el-slider__bar {
  background-color: #5D5BB5;
}

/deep/ .el-slider__runway {
  background-color: #5D5BB5;
}

/deep/ .el-slider__button {
  border-color: #5D5BB5;
}

.audio-container {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

</style>
